<template>
  <div class="panel">
    <div>
      <div style="display: flex; justify-content: space-between;">
        <strong>Бүсийн урамшуулалын жагсаалт</strong>
        <el-button type="success" size="small" @click="addIncentives()"
          >Нэмэх</el-button
        >
      </div>
      <el-select v-model="zone">
        <el-option
          v-for="(zoneName, zoneIndex) in zoneList"
          :key="zoneIndex"
          :label="zoneName"
          :value="zoneName"
        ></el-option>
      </el-select>
      <el-tabs>
        <el-tab-pane label="Нэмэлт">
          <el-table :data="addIncentivesData" v-loading="loading">
            <el-table-column label="Бүсийн нэр" prop="delivery_zone">
              <template slot-scope="scope">
                <span>
                  {{
                    scope.row.delivery_zone === "SOUTH_ZONE"
                      ? "Хан-Уул бүс"
                      : scope.row.delivery_zone === "CENTRAL_ZONE"
                      ? "Төвийн бүс"
                      : "Яармаг бүс"
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="Эхлэх цаг"
              prop="start_date"
            ></el-table-column>
            <el-table-column
              label="Дуусах цаг"
              prop="end_date"
            ></el-table-column>
            <el-table-column align="center">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="detailIncentives(scope.row, 'add')"
                  >Дэлгэрэнгүй</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="Үндсэн">
          <el-table :data="defaultIncentivesData" v-loading="loading">
            <el-table-column label="Бүсийн нэр" prop="delivery_zone">
              <template slot-scope="scope">
                <span>
                  {{
                    scope.row.delivery_zone === "SOUTH_ZONE"
                      ? "Хан-Уул бүс"
                      : scope.row.delivery_zone === "CENTRAL_ZONE"
                      ? "Төвийн бүс"
                      : "Яармаг бүс"
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column align="center">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="detailIncentives(scope.row, 'default')"
                  >Дэлгэрэнгүй</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import services from "../../helpers/services";
export default {
  created() {
    this.getAllIncentives();
  },
  methods: {
    async getAllIncentives() {
      this.loading = true;
      await services.getAllIncentives().then(inc => {
        if (inc.data.status === "success") {
          this.addIncentivesData = inc.data.data.filter(
            res => res.type === "additional"
          );
          this.defaultIncentivesData = inc.data.data.filter(
            res => res.type === "default"
          );
        } else {
          this.$message({
            message: inc.data.message,
            type: "error"
          });
        }
        this.loading = false;
      });
    },
    addIncentives() {
      this.$router.push({
        name: "addIncentives"
      });
    },
    detailIncentives(data, type) {
      this.$router.push({
        name: "detailIncentives",
        params: { id: data.incentive_id, type: type }
      });
    }
  },
  data() {
    return {
      loading: false,
      start_date: "",
      end_date: "",
      zone: "",
      zoneList: ["SOUTH_ZONE", "CENTRAL_ZONE", "YARMAG_ZONE"],
      addIncentivesData: [],
      defaultIncentivesData: []
    };
  }
};
</script>
<style scoped>
.image {
  width: 120px;
  height: 100px;
}
.info {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
</style>
